import { Link } from 'gatsby';
import React from 'react';
import YearConstants from '../constants/YearConstants';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; Taranui {YearConstants.CURRENT_YEAR}. All Rights Reserved.</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <Link to="/privacy">Privacy</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/terms">Terms</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/about">About</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
